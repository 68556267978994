import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://afef0d5243cb9ac0e7c9a5084035645f@o4506241271595008.ingest.sentry.io/4506241279918080',
	tracesSampleRate: 0.1
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
